<template>
  <div class="container-grid">
    <div class="menu-lateral">
      <slidebar />
    </div>
    <div class="containerx">
      <div class="row">
        <CardsComponent />
      </div>
    </div>
  </div>
</template>

<script>
import Slidebar from "@/components/Slidebar.vue";
import CardsComponent from "@/components/CardsComponent.vue";
export default {
  components: {
    Slidebar,
    CardsComponent,   
  },

  data() {
    return {
    };
  },
  created() {
  },
  methods: {
  },
  mounted(){

  },
};
</script>
<style  scoped>
.container-grid{
  display: grid;
  grid-template-columns: 100px 1fr;
}
.containerx {
  width: 98%;
}
</style>
